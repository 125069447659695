import { useEffect, useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useParams } from "react-router-dom";
import AuthService from "../services/AuthService";
import { ReactComponent as LogoSVG } from "../assets/logo_booking.svg";
import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Zoom,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import RegisterRightForm from "../components/RegisterRightForm";
import { rot13 } from "../utils/stringUtils";
import Carousel from "../components/Carousel";
import present from "../assets/present.png";

type FormData = {
  email: string;
  password: string;
};

const backgroundImages: { path: string; id: string }[] = [
  // {
  //   url: "https://static.nationalgeographicla.com/files/styles/image_3200/public/pao-de-acucar.jpg",
  //   title: "Pão de Açúcar",
  //   subtitle: "Rio de Janeiro, Brazil",
  // },
  // {
  //   url: "https://www.micechat.com/wp-content/uploads/2021/09/Magic-Kingdom-Fab-Five-Fashions.jpg",
  //   title: "Magic Kingdom",
  //   subtitle: "Walt Disney World, USA",
  // },
  // {
  //   url: "https://static.nationalgeographicla.com/files/styles/image_3200/public/06-notre-dame-cathedral-fire_0.jpg",
  //   title: "Notre-Dame Cathedral",
  //   subtitle: "Paris, France",
  // },
  // {
  //   url: "https://static.nationalgeographic.es/files/styles/image_3200/public/1-pyramids-giza-NationalGeographic_911975.jpg",
  //   title: "Pyramids of Giza",
  //   subtitle: "Giza, Egypt",
  // },
  // {
  //   url: "https://www.ngenespanol.com/wp-content/uploads/2024/06/cataratas-del-iguazu-argentina-brasil.jpg",
  //   title: "Iguazu Falls",
  //   subtitle: "Argentina/Brazil",
  // },
  // {
  //   url: "https://static.nationalgeographicla.com/files/styles/image_3200/public/92491.jpg",
  //   title: "Machu Picchu",
  //   subtitle: "Peru",
  // },
  // {
  //   url: "https://i.natgeofe.com/n/a70f03bc-bef3-4bdf-ab8c-8df7ac83b954/towers-torres-del-paine-national-park-patagonia.jpg",
  //   title: "Torres del Paine",
  //   subtitle: "Patagonia, Chile",
  // },
  // {
  //   url: "https://static.nationalgeographicla.com/files/styles/image_3200/public/nationalgeographic2714870_0.jpg",
  //   title: "Galápagos Islands",
  //   subtitle: "Ecuador",
  // },
  // {
  //   url: "https://i.natgeofe.com/n/874df281-d3e0-489a-98c0-6b840023b828/newyork_NationalGeographic_2328428_4x3.jpg",
  //   title: "New York City",
  //   subtitle: "USA",
  // },
  // {
  //   url: "https://static.nationalgeographic.es/files/styles/image_3200/public/Italy-Dark-Card.jpg?w=1600",
  //   title: "Colosseum",
  //   subtitle: "Rome, Italy",
  // },
  // {
  //   url: "https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2024/01/Runway-2024-01-16T04_40_14.438Z-Erase-and-Replace-Erase-selected-area-no-people.png",
  //   title: "Cancún",
  //   subtitle: "Mexico",
  // },
  // {
  //   url: "https://www.egiptoexclusivo.com/wp-content/uploads/2022/12/sharm-el-sheikh-ciudad.jpg",
  //   title: "Sharm El-Sheikh",
  //   subtitle: "Egypt",
  // },
  // {
  //   url: "https://www.ngenespanol.com/wp-content/uploads/2022/11/emiratos-arabes-unidos.jpg",
  //   title: "Burj Khalifa",
  //   subtitle: "Dubai, UAE",
  // },
  // {
  //   url: "https://static.nationalgeographicla.com/files/styles/image_3200/public/hagia-sophia-mosque-b4753j.jpg?w=1900&h=1267",
  //   title: "Hagia Sophia",
  //   subtitle: "Istanbul, Turkey",
  // },
  // {
  //   url: "https://viajes.nationalgeographic.com.es/medio/2020/07/17/samana_8552d160_1200x630.jpg",
  //   title: "Samaná",
  //   subtitle: "Dominican Republic",
  // },
  // {
  //   url: "https://static.nationalgeographicla.com/files/styles/image_3200/public/bookstore-buenosaires-argentina.jpg?w=1600",
  //   title: "El Ateneo Grand Splendid",
  //   subtitle: "Buenos Aires, Argentina",
  // },

  {
    id: "Pão_de_Açúcar",
    path: "/destination_images/Pão_de_Açúcar-Rio_de_Janeiro_Brazil.jpg",
  },
  {
    id: "Magic_Kingdom",
    path: "/destination_images/Magic_Kingdom-Walt_Disney_World_USA.jpg",
  },
  {
    id: "Notre-Dame_Cathedral",
    path: "/destination_images/Notre-Dame_Cathedral-Paris_France.jpg",
  },
  {
    id: "Pyramids_of_Giza",
    path: "/destination_images/Pyramids_of_Giza-Giza_Egypt.jpg",
  },
  {
    id: "Iguazu_Falls",
    path: "/destination_images/Iguazu_Falls-Argentina_Brazil.jpg",
  },
  { id: "Machu_Picchu", path: "/destination_images/Machu_Picchu-Peru.jpg" },
  {
    id: "Torres_del_Paine",
    path: "/destination_images/Torres_del_Paine-Patagonia_Chile.jpg",
  },
  {
    id: "Galápagos_Islands",
    path: "/destination_images/Galápagos_Islands-Ecuador.jpg",
  },
  { id: "New_York_City", path: "/destination_images/New_York_City-USA.jpg" },
  { id: "Colosseum", path: "/destination_images/Colosseum-Rome_Italy.jpg" },
  { id: "Cancún", path: "/destination_images/Cancún-Mexico.png" },
  {
    id: "Sharm_El-Sheikh",
    path: "/destination_images/Sharm_El-Sheikh-Egypt.jpg",
  },
  {
    id: "Burj_Khalifa",
    path: "/destination_images/Burj_Khalifa-Dubai_UAE.jpg",
  },
  {
    id: "Hagia_Sophia",
    path: "/destination_images/Hagia_Sophia-Istanbul_Turkey.jpg",
  },
  { id: "Samaná", path: "/destination_images/Samaná-Dominican_Republic.jpg" },
  {
    id: "El_Ateneo_Grand_Splendid",
    path: "/destination_images/El_Ateneo_Grand_Splendid-Buenos_Aires_Argentina.jpg",
  },
];

const RegisterRight = () => {
  // State management
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const authService = new AuthService();
  const params = useParams();
  const { t, i18n } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  // Extract and decode email token from URL
  const emailId: string | null = new URLSearchParams(
    window.location.search
  ).get("token");
  const lang: string = String(params.lang);
  let email: string = "";

  if (emailId) {
    try {
      email = atob(rot13(decodeURI(emailId))); // base64->rot13->encodeURI
      authService.id = email;
    } catch (error) {
      console.error("Invalid email token:", error);
    }
  }

  // Handle email access and language change
  useEffect(() => {
    if (email) authService.pageAccess(email);
  }, [email]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  // Form submission handler
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const response: boolean = await authService.register(data);

    setLoading(false);

    if (response) {
      setSuccess(true);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          position: "relative",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          overflow: "hidden",
        }}
      >
        <Carousel backgroundImages={backgroundImages} />
      </Grid>

      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        sx={{
          display: "flex",
          alignItems: "center",
          maxHeight: "100%",
          p: 4,
        }}
        square
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Container
            sx={{
              position: "relative",
              top: "-2rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <LogoSVG style={{ maxWidth: "40%" }} />
          </Container>

          {!success ? (
            <>
              {/* Promotional message */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily:
                      '"Blue Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                    fontSize: "25px",
                    fontWeight: 800,
                  }}
                >
                  {t("register.title")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily:
                      '"Blue Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  {t("register.subtitle")}
                </Typography>
              </Box>

              {!loading ? (
                <RegisterRightForm
                  control={control}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  errors={errors}
                />
              ) : (
                <CircularProgress color="primary" sx={{ mt: 5 }} />
              )}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Imagen encima del texto */}
              <Zoom in={success} style={{ transitionDelay: "300ms" }}>
                <img
                  src={present}
                  alt="Descuento"
                  style={{ marginBottom: 16 }}
                  width="40%"
                />
              </Zoom>

              <Typography
                variant="body2"
                sx={{
                  fontSize: "large",
                  textAlign: "center",
                  fontFamily:
                    '"Blue Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 500,
                }}
              >
                {t("register.successMessage")}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegisterRight;
