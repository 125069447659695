import { useEffect, useState } from "react";
import { Box, IconButton, Slide, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface CarouselProps {
  backgroundImages: { path: string; id: string }[];
}


const Carousel = ({ backgroundImages }: CarouselProps) => {
  // Inicializar el índice de la imagen de forma aleatoria
  const [selectedImageIndex, setSelectedImageIndex] = useState(() => {
    return Math.floor(Math.random() * backgroundImages.length);
  });
  const [slideDirection, setSlideDirection] = useState<"left" | "right">(
    "right"
  );

  const { t } = useTranslation();

  const handlePrevImage = () => {
    setSlideDirection("left"); // Se desliza a la izquierda
    setSelectedImageIndex((prevIndex: number) =>
      prevIndex === 0 ? backgroundImages.length - 1 : prevIndex - 1
    );
  };

  // Función para manejar la imagen siguiente
  const handleNextImage = () => {
    setSlideDirection("right"); // Se desliza a la derecha
    setSelectedImageIndex(
      (prevIndex: number) => (prevIndex + 1) % backgroundImages.length
    ); // Avanzar al siguiente índice
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextImage(); // Avanza a la siguiente imagen cada 5 segundos
    }, 5000);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonta
  }, [selectedImageIndex]);

  const getSlideDirection = (index: number) => {
    return index === selectedImageIndex
      ? slideDirection // Si es la imagen actual, usa la dirección global
      : slideDirection === "left"
      ? "right" // Si estamos retrocediendo, las otras imágenes salen hacia la izquierda
      : "left"; // Si estamos avanzando, las otras imágenes salen hacia la derecha
  };

  return (
    <>
      {backgroundImages.map((image, index) => (
        <Slide
          key={index}
          direction={getSlideDirection(index)}
          in={index === selectedImageIndex}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box
            sx={{
              backgroundImage: `url(${image.path})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative", // Para que el contenido del título se posicione encima
              height: "100%",
              width: "100%",
            }}
          >
            {/* Contenedor del título y subtítulo */}
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo semitransparente
                color: "white",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Box>
                <Typography variant="h6">{t(`register.backgroundImages.${image.id}.title`)}</Typography>
                <Typography variant="body2">{t(`register.backgroundImages.${image.id}.subtitle`)}</Typography>
              </Box>
            </Box>
          </Box>
        </Slide>
      ))}

      <IconButton
        onClick={handlePrevImage}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
      >
        <KeyboardArrowLeft sx={{ fontSize: 30 }} />
      </IconButton>

      <IconButton
        onClick={handleNextImage}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
      >
        <KeyboardArrowRight sx={{ fontSize: 30 }} />
      </IconButton>
    </>
  );
};

export default Carousel;
