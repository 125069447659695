import { Navigate, Route, Routes } from "react-router-dom";
import RegisterRight from "./pages/RegisterRight";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/es" />} />
    <Route path="/:lang/:token?" element={<RegisterRight />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default AppRoutes;
