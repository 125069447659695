export function rot13(str: string): string {
  return str.replace(/[A-Za-z]/g, (char) => {
    const base = char <= "Z" ? "A".charCodeAt(0) : "a".charCodeAt(0);
    const offset = (char.charCodeAt(0) - base + 13) % 26;
    return String.fromCharCode(base + offset);
  });
}

export function validRUT(rut: string): boolean {
  // Removes any character that is not a digit or 'k'
  const cleanRUT = rut.replace(/[^0-9kK]/g, "");

  // Splits the body from the verifier digit
  const body = cleanRUT.slice(0, -1);
  const dv = cleanRUT.slice(-1).toLowerCase();

  // Calculates the verifier digit
  let sum = 0;
  let multiplier = 2;

  // Iterates through the body from right to left
  for (let i = body.length - 1; i >= 0; i--) {
    sum += multiplier * +body.charAt(i); // Converts string to number
    multiplier = multiplier < 7 ? multiplier + 1 : 2;
  }

  const expectedDV = 11 - (sum % 11);
  let calculatedDV = "";

  if (expectedDV === 11) {
    calculatedDV = "0";
  } else if (expectedDV === 10) {
    calculatedDV = "k";
  } else {
    calculatedDV = String(expectedDV);
  }

  // Compares the calculated DV with the provided DV
  return dv === calculatedDV;
}
