import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDXWClVPD3DAHVcjU2E-tozrbD7Klsxlf4",
  authDomain: "phishing-september.firebaseapp.com",
  projectId: "phishing-september",
  storageBucket: "phishing-september.appspot.com",
  messagingSenderId: "19818683020",
  appId: "1:19818683020:web:1281251e2b552392fa79a9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);