import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  InputAdornment,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

type RegisterRightFormProps = {
  control: any;
  handleSubmit: any;
  onSubmit: any;
  errors: any;
};

interface Country {
  name: string;
  flag: string;
}

const roomTypes = ["Single", "Double", "Suite"]; // List of room types

const labelStyle = {
  fontFamily:
    'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  fontSize: "14px",
  fontWeight: 500,
  color: "rgb(26, 26, 26)",
  marginBottom: "4px",
};

const RegisterRightForm = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
}: RegisterRightFormProps) => {
  const { t, i18n } = useTranslation(); // Hook for translations
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        // Map the response to include only name and flag
        const countryData = data.map((country: any) => ({
          name: country.name.common,
          flag: country.flags.png,
        }));
        setCountries(countryData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();

        // Obtén el idioma actual de i18n
        const currentLanguage = i18n.language;

        // Mapea los países y selecciona el nombre en el idioma correspondiente
        const countryData = data.map((country: any) => {
          // Si el idioma es español, usa translations.spa.common
          const name =
            currentLanguage === "es"
              ? country.translations?.spa?.common
              : country.name.common; // Usa la traducción en español o el nombre por defecto // En otros casos, usa la traducción para el idioma actual o el nombre por defecto
          return {
            name: name,
            flag: country.flags.png,
          };
        });

        setCountries(countryData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [i18n.language]); // Dependencia en el cambio de idioma

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      //sx={{ mt: 3, height: "60%" }}
    >
      <Grid container spacing={2}>
        {/* First Name Field */}
        <Grid item xs={12} sm={6}>
          <InputLabel sx={labelStyle}>
            {t("register.form.firstName")}
          </InputLabel>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{ required: t("register.form.requiredError") }}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                placeholder={t("register.form.placeholders.firstName")}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                {...field}
                InputProps={{
                  endAdornment: !!errors.firstName ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon color="error" />
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
          />
        </Grid>

        {/* Last Name Field */}
        <Grid item xs={12} sm={6}>
          <InputLabel sx={labelStyle}>{t("register.form.lastName")}</InputLabel>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{ required: t("register.form.requiredError") }}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                placeholder={t("register.form.placeholders.lastName")}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                {...field}
                InputProps={{
                  endAdornment: !!errors.lastName ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon color="error" />
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
          />
        </Grid>

        {/* Email Field */}
        <Grid item xs={12}>
          <InputLabel sx={labelStyle}>{t("register.form.email")}</InputLabel>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: t("register.form.requiredError"),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t("register.form.emailError"),
              },
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                placeholder={t("register.form.placeholders.email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                {...field}
                InputProps={{
                  endAdornment: !!errors.email ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon color="error" />
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
          />
        </Grid>

        {/* Room Type Field */}
        <Grid item xs={12} sm={6}>
          <InputLabel sx={labelStyle}>{t("register.form.roomType")}</InputLabel>
          <Controller
            name="roomType"
            control={control}
            defaultValue=""
            rules={{ required: t("register.form.requiredError") }}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size="small"
                  placeholder={t("register.form.placeholders.roomType")}
                >
                  {roomTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {t(`register.form.roomTypes.${type.toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        {/* Number of Guests Field */}
        <Grid item xs={12} sm={6}>
          <InputLabel sx={labelStyle}>
            {t("register.form.numberOfGuests")}
          </InputLabel>
          <Controller
            name="numberOfGuests"
            control={control}
            defaultValue=""
            rules={{ required: t("register.form.requiredError") }}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                type="number"
                placeholder={t("register.form.placeholders.numberOfGuests")}
                error={!!errors.numberOfGuests}
                helperText={errors.numberOfGuests?.message}
                {...field}
                InputProps={{
                  endAdornment: !!errors.numberOfGuests ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon color="error" />
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
          />
        </Grid>

        {/* Country Select Field */}
        <Grid item xs={12}>
          <InputLabel sx={labelStyle}>{t("register.form.country")}</InputLabel>
          <Controller
            name="country"
            control={control}
            defaultValue={null}
            rules={{ required: t("register.form.requiredError") }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={countries}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props}>
                    <img
                      src={option.flag}
                      alt={`Flag of ${option.name}`}
                      width="20"
                      height="15"
                      style={{ marginRight: 10 }}
                    />
                    {option.name}
                  </li>
                )}
                onChange={(_, value) => field.onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder={t("register.form.placeholders.country")}
                    error={!!errors.country}
                    helperText={errors.country?.message}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {!!errors.country && (
                            <InputAdornment position="end">
                              <ErrorOutlineIcon color="error" />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        size="large"
      >
        {t("register.form.bookNow")}
      </Button>
    </Box>
  );
};

export default RegisterRightForm;
