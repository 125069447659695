import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import AppRoutes from "./Routes";
import "./globalStyles.css";
import { useEffect, useState } from "react";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Verifica la preferencia de tema oscuro del sistema al cargar y cuando cambie
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    // Actualiza el estado basado en la preferencia del sistema
    const handleMediaChange = () => {
      setDarkMode(mediaQuery.matches);
    };

    // Escuchar cambios en la configuración del tema del sistema
    mediaQuery.addEventListener('change', handleMediaChange);
    handleMediaChange(); // Llama inicialmente para establecer el tema basado en la preferencia actual

    // Limpiar el event listener al desmontar el componente
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
